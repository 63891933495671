import Model, { ModelError, TPayload } from "./model";

export enum EBannerSection {
    homepage = "homepage",
    homepageright = "homepageright",

    feedhome = "feedhome", //
    feedhomeleft = "feedhomeleft", //
    feedhomeleftTop = "feedhomeleftTop", //
    feedhomeright = "feedhomeright", //

    shophome = "shophome", // slide
    shophomeright = "shophomeright", // slide
    bestseller = "bestseller", // 
    flashsales = "flashsales",// 
    newarrival = "newarrival",//
    campaign = "campaign",//
    brandday = "brandday",//
    referral = "referral",

    interiorHome = "interiorhome",//
    interiorhomeright = "interiorhomeright",//
    photoGallery = "photogallery", //
    projectDetail = "projectdetail", //
    design2d3d = "design2d3d", //
    blogdetail = "blogdetail", // list
    partnerlist = "partnerlist", // 

    partnerhome = "partnerhome", // https://partner.spacet.vn/
    partnermarketing = "partnermarketing", // https://partner.spacet.vn/marketing-package

    sellerhome = "sellerhome", // list https://spacet.vn/chinh-sach/ban-hang-cung-space-t

    bulkorder = "bulkorder", // list
    affiliateProgram = "affiliateprogram", // list
};
export enum EBannerDevice {
    web = "web",
    app = "app",
    mobileweb = "mobileweb",
};
export type EBannerSize = 64 | 128 | 256 | 384 | 768 | 1024 | 1440;

export interface IBanner extends Model {
    "section": EBannerSection
    "rootUrl": string
    "mediaUrl": string
    "cacheUrl": string
    "cacheUrls": any
    "reaction": any
    "meta": {
        "title": string
        "description": string
        "altText": string
    }
}

export default class Banner extends Model<IBanner> implements IBanner {
    public "section": EBannerSection
    public "rootUrl": string
    public "mediaUrl": string
    public "cacheUrl": string
    public "cacheUrls": any
    public "reaction": any
    public "meta": {
        "title": string
        "description": string
        "altText": string
    }

    constructor(data?: IBanner) {
        super(data);
        Object.assign(this, data);
    }

    public static async getBanners({
        sections,
        devicetype,
        width,
    }: {
        sections: EBannerSection[],
        devicetype: EBannerDevice,
        width?: EBannerSize
    }) {
        const response = await this.fetch({
            ["endPoint"]: `banner`,
            ["method"]: "GET",
            ["headers"]: {
                // "Content-Type": "application/json",
                ...sections ? { "sections": (sections.toString()), } : undefined,
                ...devicetype ? { "devicetype": devicetype.toString(), } : undefined,
                ...width ? { "width": width.toString(), } : undefined
            }
        });
        if (response instanceof ModelError) {
            return response;
        }
        const payload = (await response) as TPayload<IBanner[]>;
        return payload.data
    }
}